import React, { useState } from "react"
import MyLink from "../components/link"
import Services from "../includes/services"

export default function Navigation() {
    const [navOpen, setNavOpen] = useState(false);

    let path = typeof window !== 'undefined' ? window.location.pathname : '';
    let service_paths = [
        '/website-development/',
        '/ecommerce-website-development/',
        '/website-maintenance/',
        '/development-for-design-agencies/',
        '/statically-generated-websites/',
        '/digital-marketing/',
        '/search-engine-optimisation/',
        '/hosting-services/'
    ];
    let service_class = (service_paths.indexOf(path) > -1) ? 'active' : '';

    function closeNav() {
        setNavOpen(false);
    }

    function handleNav(e) {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setNavOpen(value);
    }

    return (
        <nav id="navigation">
            <input onChange={handleNav} name="nav_open" checked={navOpen} type="checkbox" className="menu-icon"></input>
            <ul className="menu">
                <li><MyLink onClick={closeNav} className={`${path === '/' ? 'active' : '' }`} to="/">Home</MyLink></li>
                <li className="has-dropdown">
                    <span className={service_class}>Services</span>
                    <div className="dropdown-holder dropdown-dark">
                        <ul className="dropdown">
                            <Services onClick={closeNav} />
                        </ul>
                    </div>
                </li>

                {/*
                <li><MyLink onClick={closeNav} className={`${path === '/blog/' ? 'active' : '' }`} to="/blog/">Blog</MyLink></li>
                <li><MyLink onClick={closeNav} className={`${path === '/get-a-quote/' ? 'active' : '' }`} to="/get-a-quote/">Get a Quote</MyLink></li>
                */}

                <li><MyLink onClick={closeNav} className={`${path === '/contact/' ? 'active' : '' }`} to="/contact/">Contact</MyLink></li>
                <li><MyLink onClick={closeNav} className={`${path === '/get-a-quote/' ? 'active' : '' }`} to="/get-a-quote/">Get a Quote</MyLink></li>
            </ul>
        </nav>
    )
}