import React from "react"
import MyLink from "../components/link"

export default function Services({ onClick }) {
    let path = typeof window !== 'undefined' ? window.location.pathname : '';

    return (
        <React.Fragment>
            <li><MyLink onClick={onClick} className={`active-blue ${path === '/website-development/' ? 'active' : '' }`} to="/website-development/">Website Development</MyLink></li>
            <li><MyLink onClick={onClick} className={`active-blue ${path === '/website-maintenance/' ? 'active' : '' }`} to="/website-maintenance/">Website Maintenance</MyLink></li>
            {/* <li><MyLink onClick={onClick} className={`active-blue ${path === '/ecommerce-website-development/' ? 'active' : '' }`} to="/ecommerce-website-development/">E-Commerce Development</MyLink></li> */}
            {/* <li><MyLink onClick={onClick} className={`active-blue ${path === '/statically-generated-websites/' ? 'active' : '' }`} to="/statically-generated-websites/">Statically Generated Websites</MyLink></li> */}
            <li><MyLink onClick={onClick} className={`active-blue ${path === '/development-for-design-agencies/' ? 'active' : '' }`} to="/development-for-design-agencies/">Development for Design Agencies</MyLink></li>
            <li><MyLink onClick={onClick} className={`active-green ${path === '/hosting-services/' ? 'active' : '' }`} to="/hosting-services/">Hosting Services</MyLink></li>
            {/* <li><MyLink onClick={onClick} className={`active-green ${path === '/domain-registration-and-management/' ? 'active' : '' }`} to="/domain-registration-and-management/">Domain Registration & Management</MyLink></li> */}
            <li><MyLink onClick={onClick} className={`active-purple ${path === '/digital-marketing/' ? 'active' : '' }`} to="/digital-marketing/">Digital Marketing</MyLink></li>
            {/* <li><MyLink onClick={onClick} className={`active-purple ${path === '/social-media-marketing/' ? 'active' : '' }`} to="/social-media-marketing/">Social Media Marketing</MyLink></li> */}
            {/* <li><MyLink onClick={onClick} className={`active-purple ${path === '/search-engine-optimisation/' ? 'active' : '' }`} to="/search-engine-optimisation/">Search Engine Optimisation</MyLink></li> */}
        </React.Fragment>
    )
}